<script setup lang="ts">
import ApplicationContainer from '@/components/ApplicationContainer'
import { IDigitalCard } from '@/interface/home/IHomeDigitalPartner';
import { useHomeStore } from '@/store/home.store'
import PaginationComponent from '@/components/PaginationComponent'
import CardForm from '@/components/form/CardForm'

const whyUs = useHomeStore().getWhyUs
</script>

<template>
  <ApplicationContainer :title="whyUs.title" :copy="whyUs.copy" v-if="whyUs">
    <PaginationComponent
      :item-list="whyUs.reasonCards"
      :page-size="4"
      v-if="whyUs.reasonCards"
      v-slot="{ items }"
    >
    <div class="flex flex-wrap justify-center gap-6 mt-10 mb-16">
      <CardForm
        v-for="element in (items as IDigitalCard[])"
        :key="element.id"
        :title="element.title"
        :describe="element.copy"
        :icon="element.icon"
        class="reason--card"
      />
    </div>
    </PaginationComponent>
  </ApplicationContainer>
</template>

<style scoped lang="scss">
.reason--card {
  @media screen and (min-width: 900px) {
    width: 48%;
  }
  :deep(.color-title-dark) {
    font-size: clamp(16px, 9.5vw, 36px) !important;
  }

  :deep(.color-black) {
    font-size: 16px !important;
    font-weight: 550;
  }
}
</style>
