<script setup lang="ts">
import { isClient } from '@/utils/isClient'

const scrollSection = () => {
  if (!isClient) return
  const element = document.getElementById('icon-arrow-section')
  if (element)
    window.scrollTo({
      top: window.scrollY + element.getBoundingClientRect().top + 60,
      behavior: 'smooth',
    })
}
</script>

<template>
  <v-icon
    id="icon-arrow-section"
    icon="bi-arrow-right"
    class="color-white absolute bottom-8 left-1/2 cursor-pointer arrow--animation horizontal--hidden"
    scale="2"
    @click="scrollSection()"
  />
</template>

<style scoped>
.arrow--animation {
  -webkit-animation: arrow-section 1s infinite;
  animation: arrow-section 1s infinite;
  padding: 1rem 0;
}

@media (orientation: landscape) and (max-height: 400px) {
  .horizontal--hidden {
    display: none;
  }
}

@keyframes arrow-section {
  0%,
  100% {
    transform: translate(-50%, 25%);
    animation-timing-function: cubic-bezier(0.8, 0, 1, 1);
  }

  50% {
    transform: translate(-50%, 0);
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
  }
}
</style>
