<script setup lang="ts">
import type { ICommonPanel } from '@/interface/commonElements/ICommonPanel'
import ServicePanelHeader from '../panels/ServicesPanelHeader.vue'
import ImgComponent from '@/components/media/ImgComponent.vue'
import { HeaderPanel } from '@/enums/HeaderPanel'
import HtmlFormatter from '../HtmlFormatter.vue'
import CardForm from '../form/CardForm.vue'
import { Routes } from '@/enums/Routes'

interface Props {
  title: string
  panel: ICommonPanel[]
}
const props = defineProps<Props>()

const activeTabPanel = ref(props.panel[0])
const changeTab = (event: ICommonPanel) => {
  activeTabPanel.value = event
}
</script>

<template>
  <CardForm class="mt-6 min-h-24 el-card-padding" :padding="0">
    <div
      class="flex flex-col gap-4 xl:flex-row-reverse lg:justify-between lg:items-center lg:w-full"
    >
      <ImgComponent
        v-if="activeTabPanel.image"
        :src="activeTabPanel.image"
        class="self-left xl:aspect-square xl:max-w-[450px] object-scale-down h-fit rounded-[16px]"
        alt="info"
      />
      <div class="flex flex-col flex-wrap lg:flex-row lg:w-2/3">
        <p class="color-title-dark font-bold w-4/5 mb-2 card" v-text="title" />
        <ServicePanelHeader
          :panel-header="panel"
          :type="HeaderPanel.MICRO"
          @change-tab="changeTab"
          class="w-[95%]"
        />
        <div class="min-h-[240px] w-fit">
          <p
            class="text-sm font-bold color-title-dark text-left mb-3"
            v-text="activeTabPanel.title"
          />
          <HtmlFormatter
            v-if="activeTabPanel.headerContent"
            :html-input="activeTabPanel.headerContent"
            class="w-[95%] height--custom"
          />
          <NuxtLink
            :to="`${Routes.SERVICES}?id=${activeTabPanel.id}#body-service-panel`"
          >
            <button
              class="color-link text-xs font-bold underline border-none text-left mt-6"
            >
              Read more
              <span class="visually-hidden">Switch to service page</span>
            </button>
          </NuxtLink>
        </div>
      </div>
    </div>
  </CardForm>
</template>

<style scoped>
.card {
  font-size: 30px;
  line-height: 40px;
}

.height--custom {
  height: 116px;
  overflow: hidden;
}

@media screen and (min-width: 1280px) {
  .card {
    font-size: 36px;
    line-height: 50px;
  }
}
</style>
