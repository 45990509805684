<script setup lang="ts">
import PartnersComponent from '@/components/home/PartnersComponent.vue'
import { useCommonElementsStore } from '@/store/commonElements.store'
import HeaderSection from '@/components/home/HeaderHomeSection.vue'
import TechnologyPanel from '@/components/panels/TechnologyPanel.vue'
import OfferSection from '@/components/home/OfferSection.vue'
import { useHomeStore } from '@/store/home.store'
import InformationSection from '@/components/home/InformationSection.vue'
import WhyUsSection from '@/components/home/WhyUseSection.vue'

const store = useHomeStore()
const commonStore = useCommonElementsStore()

useHead({
  title: 'First we solve the problem. Then we write the code',
  meta: [
    {
      name: 'description',
      content:
        'We believe that business, software and IT needs must be delivered in a new and more future-oriented way. ' +
        'Needs must be realized faster and the solutions must create more value to maintain competitive advantage ' +
        'in a digital world that moves at a fast pace.',
    },
  ],
})
</script>

<template>
  <div>
    <HeaderSection v-if="store.getHeader" :header="store.getHeader" />
    <InformationSection
      v-if="store.getInformation && commonStore.getPanels"
      :panel="commonStore.getPanels"
      :info="store.getInformation"
    />
    <PartnersComponent v-if="store.getPartners" v-bind="store.getPartners" />
    <TechnologyPanel
      v-if="store.getTechnology && commonStore.getTechnologies"
      :technologies-section="store.getTechnology"
      :technologies="commonStore.getTechnologies"
    />
    <OfferSection v-if="store.getOurOffer" :card="store.getOurOffer" />
    <WhyUsSection v-once />
  </div>
</template>
