<script setup lang="ts">
import { IPartner } from '@/interface/home/IHomePartner'
import ImgComponent from '@/components/media/ImgComponent.vue'
import { PropType } from 'nuxt/dist/app/compat/capi'

defineProps({
  title: {
    type: String,
    required: true,
  },
  copy: {
    type: String,
    required: true,
  },
  partners: {
    type: Array as PropType<IPartner[]>,
    required: true,
  },
})
const url = (url: string): string | undefined => {
  if (url && url.match(/^https?:\/\/.*$/)) return url
  if (url) return 'https://' + url
}
</script>

<template>
  <ApplicationContainer :title="title" :describe="copy">
    <ElCarousel
      :interval="3000"
      class="w-full mt-8 min-[450px]:hidden"
      v-if="partners.length"
    >
      <ElCarouselItem v-for="element in partners" :key="element.id">
        <NuxtLink :to="url(element.url)" target="_blank">
          <div class="partner-cards select-none">
            <ImgComponent
              class="p-4 h-[64px] md:h-[92px] select-none pointer-events-none"
              :src="element.logo"
              dropable="false"
              alt=""
            />
            <h2
              class="tetx-[12px] color-title-dark sm:text-sm font-bold p-4 select-none text-center hidden min-[360px]:block"
              v-text="element.headline"
            />
          </div>
        </NuxtLink>
      </ElCarouselItem>
    </ElCarousel>

    <ElCarousel
      :interval="0"
      type="card"
      class="w-full mt-8 hidden min-[450px]:block inherit"
      v-if="partners.length"
    >
      <ElCarouselItem v-for="element in partners" :key="element.id">
        <NuxtLink :to="url(element.url)" target="_blank">
          <div class="partner-cards select-none">
            <ImgComponent
              class="p-4 h-[64px] md:h-[92px] select-none pointer-events-none"
              :src="element.logo"
              dropable="false"
              alt="element"
            />
            <h2
              class="tetx-[12px] color-title-dark sm:text-sm font-bold p-4 select-none text-center hidden min-[360px]:block"
              v-text="element.headline"
            />
          </div>
        </NuxtLink>
      </ElCarouselItem>
    </ElCarousel>
  </ApplicationContainer>
</template>

<style scoped lang="scss">
.partner-cards {
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: boxshadow 0.4s ease;
  margin: 1rem auto;
  border-radius: 1rem;
  width: 70%;

  .is-active & {
    box-shadow: var(--boxshadow-card);
  }
}

:deep(.el-carousel__container) {
  height: fit-content;
  min-height: 160px;

  @media screen and (min-width: 450px) {
    min-height: 220px;
  }
}

:deep(.el-carousel__item:not(.is-active)) {
  a {
    pointer-events: none;
  }
}
</style>
